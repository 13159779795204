export enum RolUsuario {
  Administrador = 'administrador',
  ResponsableAsesor = 'responsable-asesor',
  AdministradorLaboratorio = 'administrador-laboratorio',
  ResponsableLaboratorio = 'responsable-laboratorio',
}

export enum EstadoType {
  Activo = 'enabled',
  Inactivo = 'disabled',
}

export enum EstadoTicket {
  Abierto = 'open',
  Cerrado = 'closed',
}

export enum TipoTicket {
  Administrativo = 'administrativo',
  SoporteBioquimico = 'soporte-bioquimico',
  SoporteTecnico = 'soporte-tecnico',
}
