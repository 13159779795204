import moment from 'moment'
import {randomNumber, randomString} from '../../../helpers/RandomString'
import {CreateLaboratorioDTO} from '../../../models/DTOs/laboratorio/CreateLaboratorioDTO'
import {LaboratorioAltaModel} from '../../../models/LaboratorioAltaModel'
import {PaisesTableMock} from '../paises/paisesTableMock'
import {EstadoType} from '../../../models/DTOs/common/types'

const INSTITUCION_ALTA_KEY = 'InstitucionesAltasTableMock'

export class InstitucionesAltasTableMock {
  public static table: Array<LaboratorioAltaModel> = [
    {
      Id: 1,
      Nombre: 'LaboratorioDemo',
      Responsable: {
        Email: 'adminclient@demo.com',
        Nombre: 'AdministradorLab',
      },
      // Ciudad: 'Buenos Aires',
      // Pais: 'Argentina',
      Fecha: '14/07/2023',
      // Idioma: 'español',
      InstitucionId: 1,
      Direccion: 'Carlos Tejedor 1323',
      IdentifTributaria: {
        Codigo: 'ABC',
        Numero: '123',
      },
      Telefono: '11-2233-4455',
      HusoHorario: -3,
      Estado: EstadoType.Activo,
    },
  ]

  private static _table: Array<LaboratorioAltaModel> = []

  private static save() {
    localStorage.setItem(INSTITUCION_ALTA_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const laboratoriosStorage = localStorage.getItem(INSTITUCION_ALTA_KEY)
    this._table = laboratoriosStorage !== null ? JSON.parse(laboratoriosStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static createInstitucionAlta(institucionId: number, info: CreateLaboratorioDTO) {
    this.read()
    this._table.push({
      Id: randomNumber(5),
      InstitucionId: institucionId,
      Nombre: info.Nombre,
      // Pais: PaisesTableMock.getTable().find((p) => p.Id === info.Pais)!.Nombre,
      // Ciudad: info.Ciudad,
      // Idioma: info.Idioma,
      Responsable: info.Responsable,
      Fecha: moment().format('DD/MM/YYYY'),
      Direccion: info.Direccion,
      IdentifTributaria: info.IdentifTributaria,
      Telefono: info.Telefono,
      HusoHorario: info.HusoHorario,
      Estado: EstadoType.Activo,
    })
    this.save()
  }
}
