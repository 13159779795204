import {ReactivoDTO} from '../../../models/DTOs/base-de-datos/ReactivoDTO'
import {EstadoType} from '../../../models/DTOs/common/types'

const RKEY = 'ReactivosTableMock'

export class ReactivosTableMock {
  public static table: Array<ReactivoDTO> = [
    {
      Id: 1,
      Nombre: 'Reactivo sífilis',
      Estado: EstadoType.Activo,
    },
  ]

  private static _table: Array<ReactivoDTO> = []
  private static lastId = ReactivosTableMock.getTable()[ReactivosTableMock.getTable().length - 1].Id

  private static read() {
    const storage = localStorage.getItem(RKEY)
    this._table = storage !== null ? JSON.parse(storage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  private static save() {
    localStorage.setItem(RKEY, JSON.stringify(this._table))
  }

  static createReactivo(descripcion: string) {
    this.read()
    this._table.push({
      Id: this.lastId + 1,
      Nombre: descripcion,
      Estado: EstadoType.Activo,
    })
    this.save()
  }

  static updateReactivo(id: number, descripcion: string) {
    this.read()
    const reactivo = this.getTable().find((a) => a.Id === id)
    if (!reactivo) return

    reactivo.Nombre = descripcion

    this.save()
  }

  static updateStatus(id: number) {
    this.read()
    const reactivo = this.getTable().find((a) => a.Id === id)
    if (!reactivo) return

    reactivo.Estado =
      reactivo.Estado === EstadoType.Activo ? EstadoType.Inactivo : EstadoType.Activo

    this.save()
  }

  static upsert(descripcion: string, id?: number) {
    this.read()

    const reactivo = this.getTable().find((a) => a.Id === id)

    if (!reactivo) {
      this._table.push({
        Id: this.lastId + 1,
        Nombre: descripcion,
        Estado: EstadoType.Activo,
      })
    } else {
      reactivo.Nombre = descripcion
    }

    this.save()
  }
}
