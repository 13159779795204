import moment from 'moment'

export function formatearHora(fecha: string | null) {
  return !fecha ? '-' : moment(fecha, 'DD-MM-YYYY HH:mm:ss').format('HH:mm:ss')
}

export function formatearDia(fecha: string | null) {
  return !fecha ? '-' : moment(fecha, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY')
}

export function diasHorasMinutos(min: number) {
  let tiempo = min
  let dias = 0
  let horas = 0
  let minutos = 0

  while (tiempo > 0) {
    if (tiempo >= 1440) {
      dias += 1
      tiempo -= 1440
    } else {
      if (tiempo >= 60) {
        horas += 1
        tiempo -= 60
      } else {
        minutos += 1
        tiempo -= 1
      }
    }
  }

  return `${dias} dias ${horas} horas ${minutos} minutos`
}

export const timezoneOffset = moment().utcOffset() / 60
