import {useIntl} from 'react-intl'
import {FC} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {UserModel} from '../../models/UserModel'
import TituloBreadcrumbs from '../../components/UI/TituloBreadCrumbs'
import CardButton from '../../components/UI/card-button/CardButton'
import {RolUsuario} from '../../models/DTOs/common/types'

const DashboardWrapper: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const intl = useIntl()

  return (
    <>
      <TituloBreadcrumbs titulo={intl.formatMessage({id: 'MENU.DASHBOARD'})} />

      <div className='row'>
        {user.Rol.Name === RolUsuario.Administrador && (
          <>
            <div className='col-4 my-5'>
              <CardButton
                title={intl.formatMessage({id: 'MENU.DB'})}
                description={intl.formatMessage({id: 'MANAGE.DB'})}
                iconSVG='/media/icons/duotune/files/fil002.svg'
                onClick={() => (window.location.href = '/database')}
              />
            </div>
            <div className='col-4 my-5'>
              <CardButton
                title={intl.formatMessage({id: 'MENU.TITLE.USERS'})}
                description={intl.formatMessage({id: 'MANAGE.USERS'})}
                iconSVG='/media/icons/duotune/communication/com005.svg'
                onClick={() => (window.location.href = '/userslist')}
              />
            </div>
            <div className='col-4 my-5'>
              <CardButton
                title={intl.formatMessage({id: 'MENU.TITLE.INSTITUTIONS'})}
                description={intl.formatMessage({id: 'MANAGE.INSTITUTIONS'})}
                iconSVG='/media/icons/duotune/finance/fin001.svg'
                onClick={() => (window.location.href = '/labs')}
              />
            </div>
            <div className='col-4 my-5'>
              <CardButton
                title={intl.formatMessage({id: 'HEADER.LABS_GROUPS'})}
                description={intl.formatMessage({id: 'MANAGE.LABS_GROUPS'})}
                iconSVG='/media/icons/duotune/abstract/abs013.svg'
                onClick={() => (window.location.href = '/labsgroups')}
              />
            </div>
          </>
        )}

        <div className='col-4 my-5'>
          <CardButton
            title={intl.formatMessage({id: 'MENU.TITLE.SUPPORT'})}
            description={intl.formatMessage({id: 'MANAGE.TICKETS'})}
            iconSVG='/media/icons/duotune/communication/com007.svg'
            onClick={() => (window.location.href = '/support/tickets')}
          />
        </div>
      </div>
    </>
  )
}

export {DashboardWrapper}
