import {FC} from 'react'
import './CardButton.css'
import {toAbsoluteUrl} from '../../../helpers'
import SVG from 'react-inlinesvg'

type Props = {
  onClick: () => void
  title: string
  description: string
  icon?: string
  iconSVG?: string
}

const CardButton: FC<Props> = ({description, onClick, title, icon, iconSVG}) => {
  return (
    <div className='card-button' onClick={onClick}>
      <div className='card-content'>
        <div className='card-header'>
          {icon && <img className='icon' src={toAbsoluteUrl(icon)} />}
          {iconSVG && <SVG src={toAbsoluteUrl(iconSVG)} className='icon' />}
          <h3 className='title'>{title}</h3>
        </div>
        <p className='description'>{description}</p>
      </div>
    </div>
  )
}

export default CardButton
