import {API_URL} from './API_URLHelper'

const ROUTES = {
  USUARIOS: `${API_URL}/usuarios`,
  ACCOUNT: `${API_URL}/usuarios/perfil`,
  PAISES: `${API_URL.replace('/admin', '')}/paises`,
  TICKETS: `${API_URL}/support/tickets`,
  LABORATORIOS: `${API_URL}/laboratorios`,
  AUTH: `${API_URL}/auth`,
  AUTH_PASSWORD: `${API_URL}/auth/password`,
  REACTIVOS: `${API_URL}/reactivos`,
  PRINCIPIOS_MEDIDA: `${API_URL}/principios-medida`,
  PLATAFORMAS: `${API_URL}/plataformas`,
  ANALITOS: `${API_URL}/analitos`,
  UNIDADES: `${API_URL}/unidades`,
  MATRICES: `${API_URL}/matrices`,
  MATERIALES_CONTROL: `${API_URL}/materiales-control`,
  MATERIALES_CONTROL_LOTES: `${API_URL}/materiales-control/:id/lotes-control`,
  GRUPOS_LABORATORIOS: `${API_URL}/grupos-laboratorios`,
}

export default ROUTES
