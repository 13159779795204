import clsx from 'clsx'
import {FC} from 'react'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {TicketsMenu} from '../../../../app/components/tickets/TicketsMenu'
import {KTSVG, toAbsoluteUrl} from '../../../../app/helpers'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3'
const toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'
const toolbarButtonIconSizeClass = 'svg-icon-1'
const toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px'

const Topbar: FC = () => {
  const {config} = useLayout()

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div
        className={clsx('d-flex align-items-center mx-3', toolbarButtonMarginClass)}
        id='kt_tickets_menu_toggle'
      >
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/communication/com007.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <TicketsMenu />
      </div>

      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img
            className='h-25px w-25px rounded'
            src={toAbsoluteUrl('/media/avatars/blank.png')}
            alt='GM'
          />
        </div>
        <HeaderUserMenu />
      </div>

      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export {Topbar}
