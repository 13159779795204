import {randomNumber, randomString} from '../../../helpers/RandomString'
import {LoteDTO} from '../../../models/DTOs/base-de-datos/LoteDTO'
import {MaterialDeControlDTO} from '../../../models/DTOs/base-de-datos/MaterialDeControlDTO'
import {EstadoType} from '../../../models/DTOs/common/types'

const KEY = 'MaterialesControlTableMock'

export class MaterialesControlTableMock {
  public static table: Array<MaterialDeControlDTO> = [
    {
      Id: 1,
      Nombre: 'Accurun 1',
      Niveles: 1,
      Estado: EstadoType.Activo,
    },
  ]

  private static _table: Array<MaterialDeControlDTO> = []
  private static lastId =
    MaterialesControlTableMock.getTable()[MaterialesControlTableMock.getTable().length - 1].Id

  private static read() {
    const storage = localStorage.getItem(KEY)
    this._table = storage !== null ? JSON.parse(storage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  private static save() {
    localStorage.setItem(KEY, JSON.stringify(this._table))
  }

  static createMaterialControl(nombre: string, nivel: number) {
    this.read()
    this._table.push({
      Id: this.lastId + 1,
      Nombre: nombre,
      Niveles: nivel,
      Estado: EstadoType.Activo,
    })
    this.save()
  }

  static updateMaterialControl(id: number, newMaterial: {Nombre: string; Nivel: number}) {
    this.read()
    const material = this.getTable().find((a) => a.Id === id)
    if (!material) return

    material.Nombre = newMaterial.Nombre
    material.Niveles = newMaterial.Nivel

    this.save()
  }

  static updateStatus(id: number) {
    this.read()
    const material = this.getTable().find((a) => a.Id === id)
    if (!material) return

    material.Estado =
      material.Estado === EstadoType.Activo ? EstadoType.Inactivo : EstadoType.Activo

    this.save()
  }

  static upsert(nombre: string, nivel: number, id?: number) {
    this.read()

    const material = this.getTable().find((a) => a.Id === id)

    if (!material) {
      this._table.push({
        Id: this.lastId + 1,
        Nombre: nombre,
        Niveles: nivel,
        Estado: EstadoType.Activo,
      })
    } else {
      material.Nombre = nombre
      material.Niveles = nivel
    }

    this.save()
  }
}
