import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {useSelector, shallowEqual} from 'react-redux'
import {UserModel} from '../../../../app/models/UserModel'
import {RootState} from '../../../../setup'
import {RolUsuario} from '../../../../app/models/DTOs/common/types'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>
      <AsideMenuItem
        id='pagPrincipal'
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {user.Rol.Name === RolUsuario.Administrador && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'MENU.TITLE.ADMIN'})}
              </span>
            </div>
          </div>
          <AsideMenuItem
            id='basededatos'
            to='/database'
            icon='/media/icons/duotune/files/fil002.svg'
            title={intl.formatMessage({id: 'MENU.DB'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            id='listaLaboratorios'
            to='/labs'
            icon='/media/icons/duotune/finance/fin001.svg'
            title={intl.formatMessage({id: 'MENU.INSTITUTIONS'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            id='gruposDeLaboratorios'
            to='/labsgroups'
            icon='/media/icons/duotune/abstract/abs013.svg'
            title={intl.formatMessage({id: 'HEADER.LABS_GROUPS'})}
            //fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            id='listaUsuarios'
            to='/userslist'
            icon='/media/icons/duotune/communication/com005.svg'
            title={intl.formatMessage({id: 'MENU.USERS'})}
            fontIcon='bi-app-indicator'
          />
        </>
      )}
    </>
  )
}
