import {MatrizDTO} from '../../../models/DTOs/base-de-datos/MatrizDTO'
import {EstadoType} from '../../../models/DTOs/common/types'

const KEY = 'MatricesTableMock'

export class MatricesTableMock {
  public static table: Array<MatrizDTO> = [
    {
      Id: 1,
      Nombre: 'Suero',
      Estado: EstadoType.Activo,
    },
  ]

  private static _table: Array<MatrizDTO> = []
  private static lastId = MatricesTableMock.getTable()[MatricesTableMock.getTable().length - 1].Id

  private static read() {
    const storage = localStorage.getItem(KEY)
    this._table = storage !== null ? JSON.parse(storage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  private static save() {
    localStorage.setItem(KEY, JSON.stringify(this._table))
  }

  static createMatriz(descripcion: string) {
    this.read()
    this._table.push({
      Id: this.lastId + 1,
      Nombre: descripcion,
      Estado: EstadoType.Activo,
    })
    this.save()
  }

  static updateMatriz(id: number, descripcion: string) {
    this.read()
    const matriz = this.getTable().find((a) => a.Id === id)
    if (!matriz) return

    matriz.Nombre = descripcion

    this.save()
  }

  static updateStatus(id: number) {
    this.read()
    const matriz = this.getTable().find((a) => a.Id === id)
    if (!matriz) return

    matriz.Estado = matriz.Estado === EstadoType.Activo ? EstadoType.Inactivo : EstadoType.Activo

    this.save()
  }

  static upsert(descripcion: string, id?: number) {
    this.read()

    const matriz = this.getTable().find((a) => a.Id === id)

    if (!matriz) {
      this._table.push({
        Id: this.lastId + 1,
        Nombre: descripcion,
        Estado: EstadoType.Activo,
      })
    } else {
      matriz.Nombre = descripcion
    }

    this.save()
  }
}
