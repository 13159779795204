import moment from 'moment'
import {CreateLaboratorioDTO} from '../../../models/DTOs/laboratorio/CreateLaboratorioDTO'
import {UpdateLaboratorioDTO} from '../../../models/DTOs/laboratorio/UpdateLaboratorioDTO'
import {LaboratorioModel} from '../../../models/LaboratorioModel'
import {PaisesTableMock} from '../paises/paisesTableMock'
import {randomNumber} from '../../../helpers/RandomString'

const INSTITUTION_KEY = 'InstitucionesTableMock'

export class InstitutionsTableMock {
  public static table: Array<LaboratorioModel> = [
    {
      Id: 2,
      Nombre: 'LaboratorioDemo',
      Responsable: {
        Nombre: 'LICON CLIENT',
        Email: 'aqcsuiteclient@gmigliarino.com',
      },
      IsActivo: true,
      IdentifTributaria: {
        Codigo: 'ABC',
        Numero: '123',
      },
      Direccion: 'Carlos Tejedor 1323',
      Fecha: '01/09/2023',
      //Ciudad: 'Buenos Aires',
      //Idioma: 'español',
      //Pais: 'Argentina',
      Telefono: '11-2233-4455',
      HusoHorario: -3,
    },
  ]

  private static _table: Array<LaboratorioModel> = []

  private static save() {
    localStorage.setItem(INSTITUTION_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const laboratoriosStorage = localStorage.getItem(INSTITUTION_KEY)
    this._table = laboratoriosStorage !== null ? JSON.parse(laboratoriosStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static updateState(institucionId: number) {
    const institucion = this._table.find((x) => x.Id === institucionId) as LaboratorioModel
    if (institucion === null) return

    institucion.IsActivo = !institucion.IsActivo

    localStorage.setItem(INSTITUTION_KEY, JSON.stringify(this._table))
  }

  static crearInstitucion(newInstitutionDTO: CreateLaboratorioDTO): LaboratorioModel {
    const newInstitution: LaboratorioModel = {
      Id: randomNumber(5),
      IsActivo: true,
      IdentifTributaria: newInstitutionDTO.IdentifTributaria,
      Nombre: newInstitutionDTO.Nombre,
      Responsable: newInstitutionDTO.Responsable,
      //Ciudad: newInstitutionDTO.Ciudad,
      Direccion: newInstitutionDTO.Direccion,
      Fecha: moment().format('DD/MM/YYYY'),
      //Idioma: newInstitutionDTO.Idioma,
      //Pais: PaisesTableMock.getTable().find((p) => p.Id === newInstitutionDTO.Pais)!.Nombre,
      Telefono: newInstitutionDTO.Telefono,
      HusoHorario: newInstitutionDTO.HusoHorario,
    }
    this.read()
    this._table.push(newInstitution)
    this.save()
    return {...newInstitution}
  }

  static updateInstitution(institucionId: number, institucionUpdate: UpdateLaboratorioDTO) {
    console.log(institucionUpdate)
    console.log(institucionId)

    const institucion = this._table.find((x) => x.Id === institucionId) as LaboratorioModel

    if (!institucion) return

    institucion.Nombre = institucionUpdate.Nombre
    institucion.Responsable = institucionUpdate.Responsable
    institucion.Direccion = institucionUpdate.Direccion
    institucion.Telefono = institucionUpdate.Telefono
    institucion.IdentifTributaria = institucionUpdate.IdentifTributaria
    institucion.HusoHorario = +institucionUpdate.HusoHorario

    localStorage.setItem(INSTITUTION_KEY, JSON.stringify(this._table))
  }
}
