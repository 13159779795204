import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, select, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../../models/UserModel'
import {setLanguage} from '../../../_metronic/i18n/Metronici18n'
import {getUsuarioByToken} from '../../modules/account/get-usuario-by-token'
import jwt_decode from 'jwt-decode'
import {RolUsuario} from '../../models/DTOs/common/types'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  UpdateToken: '[UpdateToken] Action',
  UpdateUser: '[UpdateUser] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined,
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
  refreshToken?: string
  hash?: string
}

export const reducer = persistReducer(
  {
    storage,
    key: process.env.REACT_APP_AUTH_KEY!,
    whitelist: ['user', 'accessToken', 'refreshToken'],
  },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        const refreshToken = action.payload?.refreshToken
        return {accessToken, refreshToken, user: undefined}
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserRequested: {
        return {...state, user: undefined}
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.UpdateToken: {
        const accessToken = action.payload?.accessToken
        const refreshToken = action.payload?.refreshToken
        return {...state, accessToken, refreshToken}
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string, refreshToken: string) => ({
    type: actionTypes.Login,
    payload: {accessToken, refreshToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
  updateToken: (accessToken: string, refreshToken: string) => ({
    type: actionTypes.UpdateToken,
    payload: {accessToken, refreshToken},
  }),
  updateUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
}

const getAuth = (state: any) => ({auth: state.auth})

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    const {auth} = yield select(getAuth)

    const accessToken = auth.accessToken
    const refreshToken = auth.refreshToken

    if (accessToken && refreshToken) {
      const decoded: any = jwt_decode(accessToken)

      const email = decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']
      const name = decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
      const rol = parseInt(decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'])
      const id = decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']

      const user: UserModel = {
        Id: +id,
        Auth: {
          AccessToken: accessToken,
          RefreshToken: refreshToken,
        },
        Email: email,
        Rol: {
          Id: rol,
          Name: rol === 1 ? RolUsuario.Administrador : RolUsuario.ResponsableAsesor,
        },
        IsActivo: true,
        Nombre: name,
      }

      yield put(actions.setUser(user))
    }
  })
}
