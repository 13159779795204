import React, {Suspense, lazy} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RootState} from '../../setup'
import {FallbackView} from '../../_metronic/partials'
import {UserModel} from '../models/UserModel'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {RolUsuario} from '../models/DTOs/common/types'
import {Logout} from '../pages/auth'

export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const AccountPage = lazy(() => import('../pages/accounts/AccountPage'))
  const UsersList = lazy(() => import('../pages/users/UsersList'))
  const Tickets = lazy(() => import('../pages/tickets/Tickets'))
  const LaboratoriosList = lazy(() => import('../pages/laboratorios/LaboratoriosList'))
  const BaseDeDatos = lazy(() => import('../pages/base-de-datos/BaseDeDatos'))
  const GruposLaboratorios = lazy(() => import('../pages/grupos-laboratorios/GruposLaboratorios'))

  const isAdmin = user.Rol.Name === RolUsuario.Administrador

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        {isAdmin && <Route path='/userslist' component={UsersList} />}
        {isAdmin && <Route path='/labs' component={LaboratoriosList} />}
        {isAdmin && <Route path='/database' component={BaseDeDatos} />}
        {isAdmin && <Route path='/labsgroups' component={GruposLaboratorios} />}
        <Route path='/account' component={AccountPage} />
        <Route path='/support/tickets' component={Tickets} />
        <Route path='/logout' component={Logout} />

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
