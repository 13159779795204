import {randomNumber, randomString} from '../../../helpers/RandomString'
import {CreateUsuarioDTO} from '../../../models/DTOs/usuario/CreateUsuarioDTO'
import {UserModel} from '../../../models/UserModel'
import {UsuarioDTO} from '../../../models/DTOs/usuario/UsuarioDTO'
import {RolUsuario} from '../../../models/DTOs/common/types'

const USUARIO_KEY = 'UsuariosTableMock'

export class UsersTableMock {
  public static table: Array<UserModel> = [
    {
      Id: 1,
      Email: 'aqcsuite@gmigliarino.com',
      Rol: {
        Id: 1,
        Name: 'Administrador',
      }, // Administrator
      Nombre: 'LICON',
      IsActivo: true,
      Auth: {
        AccessToken:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiTElDT04iLCJ1bmlxdWVfbmFtZSI6ImFxY3N1aXRlQGdtaWdsaWFyaW5vLmNvbSIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6MSwic3ViIjoxfQ==.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
        RefreshToken: 'refresh-token-8f3ae836da744329a6f93bf20594b5cb',
      },
    },
    {
      Id: 2,
      Email: 'dylanperro@demo.com',
      Rol: {
        Id: 2,
        Name: 'Responsable de laboratorios',
      }, // Usuario
      Nombre: 'Dylan Perro',
      IsActivo: false,
      Auth: {
        AccessToken: 'access-token-8f3ae836da744329a6f93bf20594b5cb',
        RefreshToken: 'refresh-token-8f3ae836da744329a6f93bf20594b5cb',
      },
    },
  ]

  private static _table: Array<UserModel> = []

  private static save() {
    localStorage.setItem(USUARIO_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const usuariosStorage = localStorage.getItem(USUARIO_KEY)
    this._table = usuariosStorage !== null ? JSON.parse(usuariosStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static crearUsuario(newUserDTO: CreateUsuarioDTO): UserModel {
    const newUser: UserModel = {
      Id: randomNumber(2),
      IsActivo: true,
      Email: newUserDTO.Email,
      Nombre: newUserDTO.Nombre,
      Rol: {
        Id: newUserDTO.Rol === RolUsuario.Administrador ? 1 : 2,
        Name:
          newUserDTO.Rol === RolUsuario.Administrador
            ? 'Administrador'
            : 'Responsable de laboratorios',
      },
      Auth: {
        AccessToken: 'access-token-' + randomString(32),
        RefreshToken: 'refresh-token-' + randomString(32),
      },
    }
    this.read()
    this._table.push(newUser)
    this.save()
    return {...newUser}
  }

  static updateState(usuarioId: number) {
    const usuario = this._table.find((x) => x.Id === usuarioId) as UserModel

    if (usuario === null) return

    usuario.IsActivo = !usuario.IsActivo

    localStorage.setItem(USUARIO_KEY, JSON.stringify(this._table))
  }

  static updateUser(usuarioId: number, user: {Nombre: string; Rol: number}) {
    const usuario = this._table.find((x) => x.Id === usuarioId) as UserModel

    if (!usuario) return

    usuario.Nombre = user.Nombre
    usuario.Rol = {
      Id: +user.Rol,
      Name: +user.Rol === 1 ? RolUsuario.Administrador : RolUsuario.ResponsableAsesor,
    }

    localStorage.setItem(USUARIO_KEY, JSON.stringify(this._table))
  }

  static updateEmail(usuarioId: number, email: string) {
    const usuario = this._table.find((x) => x.Id === usuarioId) as UserModel
    if (!usuario) return

    usuario.Email = email

    localStorage.setItem(USUARIO_KEY, JSON.stringify(this._table))
  }

  static updateName(usuarioId: number, name: string) {
    const usuario = this._table.find((x) => x.Id === usuarioId) as UserModel
    if (!usuario) return

    usuario.Nombre = name

    localStorage.setItem(USUARIO_KEY, JSON.stringify(this._table))
  }
}
