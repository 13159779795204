import Swal from 'sweetalert2'
import {toast} from 'react-toastify'

export function showError(title: string, desc: string, btntxt: string) {
  return Swal.fire({
    title: title,
    text: desc,
    icon: 'error',
    confirmButtonText: btntxt,
  })
}

export function showSuccess(title: string, desc: string, btntxt: string) {
  return Swal.fire({
    title: title,
    text: desc,
    icon: 'success',
    confirmButtonText: btntxt,
  })
}

export function showWarning(title: string, desc: string) {
  return Swal.fire({
    title: title,
    text: desc,
    icon: 'warning',
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar',
    showCancelButton: true,
    showConfirmButton: true,
  })
}

export function toastError(title: string) {
  return toast.error(title, {
    position: toast.POSITION.TOP_RIGHT,
  })
}

export function toastSuccess(title: string) {
  return toast.success(title, {
    position: toast.POSITION.TOP_RIGHT,
  })
}
