import {FC} from 'react'

interface Props {
  titulo: string
  breadcrumbs?: string[]
}

const TituloBreadcrumbs: FC<Props> = ({titulo, breadcrumbs}) => {
  return (
    <div className='mb-5' style={{marginTop: '-7px'}}>
      <h3>{titulo}</h3>
      <h6 className='text-muted'>{breadcrumbs?.concat(titulo).join(' - ')}</h6>
    </div>
  )
}

export default TituloBreadcrumbs
