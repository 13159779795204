import {IntlShape} from 'react-intl'

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)

export const toMinutesFromString = (time: string) => {
  const array = time.split('.')
  if (array.length === 2) {
    const dias = parseInt(array[0], 10)

    const array2 = array[1].split(':')
    const horas = parseInt(array2[0], 10)
    const minutos = parseInt(array2[1], 10)

    const tiempo = minutos + horas * 60 + dias * 24 * 60
    return tiempo
  } else {
    const array2 = time.split(':')
    const horas = parseInt(array2[0], 10)
    const minutos = parseInt(array2[1], 10)

    const tiempo = minutos + horas * 60
    return tiempo
  }
}

export const idiomaHandler = (idioma: string, intl: IntlShape) => {
  switch (idioma) {
    case 'es':
      return intl.formatMessage({id: 'LANGUAGE.SPANISH'})
    case 'en':
      return intl.formatMessage({id: 'LANGUAGE.ENGLISH'})
  }
}

export const husosHorarios: {Nombre: string; Value: number}[] = [
  {
    Nombre: 'GTM-12:00',
    Value: -12,
  },
  {
    Nombre: 'GTM-11:00',
    Value: -11,
  },
  {
    Nombre: 'GTM-10:00',
    Value: -10,
  },
  {
    Nombre: 'GTM-09:00',
    Value: -9,
  },
  {
    Nombre: 'GTM-08:00',
    Value: -8,
  },
  {
    Nombre: 'GTM-07:00',
    Value: -7,
  },
  {
    Nombre: 'GTM-06:00',
    Value: -6,
  },
  {
    Nombre: 'GTM-05:00',
    Value: -5,
  },
  {
    Nombre: 'GTM-04:00',
    Value: -4,
  },
  {
    Nombre: 'GTM-03:00',
    Value: -3,
  },
  {
    Nombre: 'GTM-02:00',
    Value: -2,
  },
  {
    Nombre: 'GTM-01:00',
    Value: -1,
  },
  {
    Nombre: 'GTM+00:00',
    Value: 0,
  },
  {
    Nombre: 'GTM+01:00',
    Value: 1,
  },
  {
    Nombre: 'GTM+02:00',
    Value: 2,
  },
  {
    Nombre: 'GTM+03:00',
    Value: 3,
  },
  {
    Nombre: 'GTM+04:00',
    Value: 4,
  },
  {
    Nombre: 'GTM+05:00',
    Value: 5,
  },
  {
    Nombre: 'GTM+06:00',
    Value: 6,
  },
  {
    Nombre: 'GTM+07:00',
    Value: 7,
  },
  {
    Nombre: 'GTM+08:00',
    Value: 8,
  },
  {
    Nombre: 'GTM+09:00',
    Value: 9,
  },
  {
    Nombre: 'GTM+10:00',
    Value: 10,
  },
  {
    Nombre: 'GTM+11:00',
    Value: 11,
  },
  {
    Nombre: 'GTM+12:00',
    Value: 12,
  },
  {
    Nombre: 'GTM+13:00',
    Value: 13,
  },
  {
    Nombre: 'GTM+14:00',
    Value: 14,
  },
]
