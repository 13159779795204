import moment from 'moment'
import {TicketModel} from '../../../models/TicketModel'

const TICKET_KEY = 'TicketsTableMock'

export class TicketsTableMock {
  public static table: Array<TicketModel> = [
    {
      Id: 1,
      Fecha: '09-04-2023 12:00',
      UltimaActualizacion: '09-04-2023 12:00',
      Estado: {
        Id: 1,
        Name: 'cerrado',
      },
      Tipo: {
        Id: 1,
        Name: 'admin',
      },
      Titulo: 'Error al crear usuario',
      Mensaje: 'No me deja crear usuario',
      // Respuestas: [
      //   {
      //     Fecha: '12-04-2023 15:00',
      //     Mensaje: 'QnVlbm8sIHlhIHNvbHVjaW9uYW1vcyB0dSBwcm9ibGVtYQ==',
      //     Usuario: 'Valentin AAA',
      //   },
      //   {
      //     Fecha: '12-04-2024 15:30',
      //     Mensaje: 'TXVjaGFzIGdyYWNpYXMh',
      //     Usuario: 'AdministradorLab',
      //   },
      // ],
      Respuestas: [],
    },
  ]

  private static _table: Array<TicketModel> = []

  private static save() {
    localStorage.setItem(TICKET_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const ticketsStorage = localStorage.getItem(TICKET_KEY)
    this._table = ticketsStorage !== null ? JSON.parse(ticketsStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static addRespuesta(ticketId: number, mensaje: string, usuario: string) {
    const ticket = this._table.find((t) => t.Id === ticketId)

    if (ticket == null) return

    ticket.Respuestas.push({
      Fecha: moment().format('DD-MM-YYYY HH:mm'),
      Mensaje: mensaje,
      Usuario: usuario,
    })

    localStorage.setItem(TICKET_KEY, JSON.stringify(this._table))
  }
}
